.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.inner .form-control {
  display: block;
  width: 100%;
  height: 32px;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 400;
}

.inner label {
  position: absolute;
  background: #fff;
  font-size: 9px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 6px;
  margin: 0 3px;
  top: -6px;
}

.inner-card {
  padding: 17px 12px;
}

.inner .switch {
  transform: rotate(90deg);
}

.inner .form-group {
  position: relative;
}

.inner textarea.form-control {
  height: auto;
}

.inner-sec button {
    margin-right: 10px;
    border-radius: 20px;
}
