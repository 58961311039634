@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');

body {
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

.btn-style {
    padding: 5px;
    border-radius: 6px;
}

.invalid-feedback {
    display: block
}

#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}

.table-img {
    width: 95px;
    height: 130px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    border-radius: 4px;
    margin-right: 10px;
    display: inline-block;
}

.unreviewed-border {
    border:2px solid red;
}

.table-img2 {
    width: 100%;
    height: 500px;
}

.table-img3 {
    width: 100%;
    height: 156px;
    cursor: pointer;
}

.table-icon {
    max-width: 35px;
}

.table-cell-image {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 23px
}
.login-div {
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center
}

.photo-mod-div {
    position: relative;
}

.photo-mod-select-div {
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 90%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

.mod-select {
    width: 100%;
}

.inner .lead {
    font-size: 1.25rem;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
}

.gx-chat-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 6px;
}

.gx-bubble {
    padding: 6px 12px;
    background-color: #ffffff;
    -webkit-border-radius: 20px 20px 20px 0px;
    -moz-border-radius: 20px 20px 20px 0px;
    border-radius: 20px 20px 20px 0px;
    position: relative;
    max-width: 200px;
    border: solid 1px #d9d9d9;
    display: inline-block;
}

.gx-chat-item.gx-flex-row-reverse .gx-bubble {
  margin-left: 0;
  background-color: #e6faff;
  -webkit-border-radius: 20px 20px 0 20px;
  -moz-border-radius: 20px 20px 0 20px;
  border-radius: 20px 20px 0 20px;
  text-align: left;
}

.gx-text-right {
    text-align: right !important;
}

.gx-mt-2, .gx-my-2 {
    margin-top: 0.5rem !important;
}

.gx-text-muted {
    color: #8c8c8c !important;
}

.gx-message, .gx-time {
    font-size: 12px;
}

.gx-time {
    font-weight: 600;
    font-size: 9px;
}

.gx-flex-row-reverse {
    flex-direction: row-reverse !important;
}

.holder_style {
  background: #fff;
  padding: 10px;
    /*overflow-y: scroll;*/
  border: 1px solid #f0f0f0;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0px 0;
}

.c-h {
    position: relative;
    width: 100%;
}

.c-h-c {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.over-c {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    right: 0;
    padding: 14px 8px 10px 8px;
    background: #fff;
}

.dd .ant-select {
    width: 100% ;
}
